var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "dialog"
  }, [_c('b-modal', {
    attrs: {
      "has-modal-card": "",
      "trap-focus": "",
      "destroy-on-hide": false,
      "aria-role": "dialog",
      "aria-modal": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_vm._t("default")];
      },
      proxy: true
    }], null, true),
    model: {
      value: _vm.islog,
      callback: function callback($$v) {
        _vm.islog = $$v;
      },
      expression: "islog"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };