<template>
  <div class="page-product-details-box">
    <PageTop :isLogin="isLogin"></PageTop>
    <HeadeSearch ref="handleGetSum"></HeadeSearch>
    <MainTabs
      :activeIndex="tabActive"
      @getCurrentData="onClickTab"
      :isHover="isHover"
      @onHover="onHoverEvent"
    ></MainTabs>
    <div class="product-top-box">
      <div class="product-left">
        <!-- 商品图片介绍 -->
        <div class="product-img-introduce">
          <!-- 单个大图单个大图 -->
          <div class="img-box-single" ref="smallBox">
            <div class="product-img" ref="smallBoxWrap">
              <div
                class="small-box"
                :style="{ 'pointer-events': isOnHover ? 'none' : '' }"
              >
                <div
                  class="mark"
                  @mouseenter.stop="onMouseMaskIn"
                  @mouseleave.stop="onMouseMaskOut"
                  @mousemove.stop="onMouseMaskMove($event)"
                ></div>
                <div
                  class="float-box"
                  ref="objFloatBox"
                  v-if="isShowfloat"
                ></div>
                <img :src="bigPicture" alt class="small-product-img" />
              </div>
              <div class="big-box" ref="objBigBox" v-if="isShowBigBox">
                <img :src="bigPicture" alt ref="objBigBoxImage" />
              </div>
            </div>
            <!--零差价标识-->
            <div class="zero-price-img" v-if="pageDataSource.isZeroPriceDifference == 1">
              <img src="@images/PC0.png">
            </div>
              <div class="hospital" v-if="pageDataSource.hospitalClassificationValue && pageDataSource.hospitalClassificationValue == '1'">
                  医院
              </div>
              <img v-if="pageDataSource.productPatent && pageDataSource.productPatent == '1'" class="db_icon" src="https://obs.pujian.com/frontend/app/components/db_icon.png" alt />
            <div class="bg-sold" v-if="invalidText.length > 0 && isLogin">
              <span>{{ invalidText }}</span>
            </div>
            <!-- <p class="invalid" v-if="isVisibleAddCart"> 库存不足 </p> -->
          </div>
          <!-- 多个图片组 -->
          <div class="img-box-more">
            <img
              @click="toSwichPicture('left')"
              class="img-left"
              src="./images/icon_left.png"
              alt
            />
            <div class="product-list-box">
              <div
                class="product-img-box"
                v-for="(item, index) in imgArr"
                :key="index"
              >
                <div class="product-img">
                  <img :src="item" alt @click="previewPictures(item)" />
                </div>
              </div>
            </div>
            <img
              @click="toSwichPicture('right')"
              class="img-right"
              src="./images/icon_right.png"
              alt
            />
          </div>
          <!-- 收藏 -->
          <div class="enshrine" v-if="!isExternal">
            <p class="enshrine-img" @click="hanndleEnshrine">
              <img
                v-if="!pageDataSource.isCollect"
                src="./images/icon_ enshrine.png"
                alt=""
              />
              <img
                v-if="pageDataSource.isCollect"
                src="./images/icon_ en.png"
                alt=""
              />
            </p>
            <p class="enshrine-text" @click="hanndleEnshrine">
              收藏商品/取消收藏
            </p>
          </div>
        </div>
        <!-- 商品文字信息介绍 -->
        <div class="product-info-introduce">
          <p class="product-name-box">
            <span
              :style="{
                background: pageDataSource.prescription.bgColor
              }"
              v-if="
                pageDataSource.prescription && pageDataSource.prescription.text
              "
              >{{ pageDataSource.prescription.text }}</span
            >
            <!-- v-if="pageDataSource.productAttribute=='1'" -->
            <font class="product-hbimg-jf" v-if="pageDataSource.productAttribute == '1'">
                返利
            </font>
              <img class="tail-hb-img" v-if="pageDataSource.activityVOList != undefined &&pageDataSource.activityVOList.length > 0 &&pageDataSource.activityVOList[0].isIntegralCommodity&&pageDataSource.activityVOList[0].isIntegralCommodity==1" src="@images/2.gif">
            【{{ pageDataSource.specifications }}】{{pageDataSource.productName }}
          </p>
          <!-- 价格和优惠活动 -->
          <div class="product-active-price">
            <div class="label-box" v-if="pageDataSource.superDiscountProductPrice">
              <p class="label-purchase">特价</p>
              <p>
                <span class="symbol">¥</span>
                <span class="purchase-price" v-if="!pageDataSource.superDiscountProductPrice">价格登录后可见</span>
                <span class="purchase-price" v-if="isExternal">价格不可见</span>
                <span class="purchase-price" v-if="pageDataSource.superDiscountProductPrice&&!isExternal">{{
                  pageDataSource.superDiscountProductPrice
                }}<span v-if="pageDataSource.sellUnit">{{'/'+pageDataSource.sellUnit}}</span></span>
              </p>
              <div class="tj-box" v-if="pageDataSource.superDiscountProductNum">{{pageDataSource.superDiscountProductNum}}{{pageDataSource.sellUnit}}起购</div>
            </div>
            <div class="label-box" v-if="pageDataSource.superDiscountProductPrice">
              <p>采购价格</p>
              <p style="text-decoration: line-through;">
                  <span>¥</span>
                  <span v-if="!pageDataSource.preferenticePrice">价格登录后可见</span>
                  <span v-if="isExternal">价格不可见</span>
                  <span v-if="pageDataSource.preferenticePrice&&!isExternal">{{
              pageDataSource.preferenticePrice
            }}<span v-if="pageDataSource.sellUnit">{{'/'+pageDataSource.sellUnit}}</span></span>
              </p>
            </div>
            <div class="label-box" v-else>
              <p class="label-purchase">采购价格</p>
              <p>
                <span class="symbol">¥</span>
                <span class="purchase-price" v-if="!pageDataSource.preferenticePrice">价格登录后可见</span>
                <span class="purchase-price" v-if="isExternal">价格不可见</span>
                <span class="purchase-price" v-if="pageDataSource.preferenticePrice&&!isExternal">
                  {{pageDataSource.preferenticePrice }}
                  <span v-if="pageDataSource.sellUnit">{{'/'+pageDataSource.sellUnit}}</span>
                </span>
              </p>
              <!-- <s>¥{{ pageDataSource.unitPrice }}</s> -->
            </div>
            <div class="label-box">
              <p>建议零售价</p>
              <p>¥{{ pageDataSource.suggestedPrice }}</p>
            </div>
             <div class="label-box">
              <p>毛利率</p>
              <p>{{ pageDataSource.grossProfitRate }}%</p>
            </div>
              <div class="label-box">
                  <p>活动</p>
                  <div>
                    <div v-if="pageDataSource.activityVOList.length > 0 && isOtherAccount==true"
                         v-for="(activity, acIndex) in pageDataSource.activityVOList" :key="acIndex">
                      <ActivityLine activeName="立减"
                                    v-if="activity.type == 1"
                                    :type="activity.type"
                                    bgColor="#FFF3F3"
                                    borderColor="#FFF3F3"
                                    color="#000000"
                                    :isDetail="true"
                                    :activeContent="activity.activityName" />
                      <ActivityLine activeName="红包"
                                    v-if="activity.type == 2 || activity.type == 4"
                                    :type="activity.type"
                                    bgColor="#FFF5D6"
                                    borderColor="#FFF5D6"
                                    color="#000000"
                                    :isDetail="true"
                                    :activeContent="activity.activityName" />
                    </div>
                  </div>
              </div>
<!--              <div class="label-box" v-if="pageDataSource.activityVOList != undefined && pageDataSource.activityVOList.length>0">-->
<!--              <p class="label-active">活动</p>-->
<!--              <p class="active-info">-->
<!--                <span ref="activeSpan">{{pageDataSource.activityVOList[0].activityName }}  </span>-->
<!--                &lt;!&ndash; 活动价约¥{{pageDataSource.activityVOList[0].activityZhPrice }} &ndash;&gt;-->
<!--              </p>-->
<!--              <div class="select-icon" v-if="isLogin && isShowTriangle">-->
<!--                <img src="./images/icon_select.png" alt />-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="label-box" v-for="(itema,index) in pageDataSource.activityVOList" :key="index"  >-->
<!--              <template v-if="index>0">-->
<!--                <p class="label-active"> </p>-->
<!--                <p class="active-info">-->
<!--                  <span ref="activeSpan">{{itema.activityName }} </span>-->
<!--                  &lt;!&ndash; 活动价约¥{{pageDataSource.activityVOList[0].activityZhPrice }} &ndash;&gt;-->
<!--                </p>-->
<!--                <div class="select-icon" v-if="isLogin && isShowTriangle">-->
<!--                  <img src="./images/icon_select.png" alt />-->
<!--                </div>-->
<!--              </template>-->
<!--            </div>-->
          </div>
          <div class="product-info-basic-box">
            <ul>
              <li>
                <p>批准文号</p>
                <p>{{ pageDataSource.approvalNumber }}</p>
              </li>
              <li>
                <p>剂型</p>
                <p>{{ pageDataSource.formulation }}</p>
              </li>
              <li>
                <p>包装</p>
                <p>{{ pageDataSource.sellUnit }}</p>
              </li>
              <li>
                <p>件装</p>
                <p>
                  {{ pageDataSource.packageNumber }}
                </p>
              </li>
              <li>
                <p>生产厂家</p>
                <p>{{ pageDataSource.produceFactory | ellipsisss }}</p>
              </li>
              <li v-if="pageDataSource.produceAddress">
                <p>产地</p>
                <p>{{ pageDataSource.produceAddress}}</p>
              </li>
              <li>
                <p>处方分类</p>
                <p>
                  <!-- <span
                    v-if="
                      pageDataSource.prescription &&
                        pageDataSource.prescription.type
                    "
                    >{{ pageDataSource.prescription }}</span
                  > -->
                  <span v-if="pageDataSource.prescription">
                    {{ pageDataSource.prescription.type }}
                    {{ pageDataSource.prescription.text }}
                  </span>
                </p>
              </li>
              <li>
                <p>效期</p>
                <p v-if="pageDataSource.nearEffectivePeriod">
                  {{ pageDataSource.nearEffectivePeriod | formatDate }}
                </p>
                <p v-else></p>
              </li>
                <li>
                    <p style="width: 85px">最后退货时间</p>
                    <p v-if="pageDataSource.lastReturnTime">
                        {{ pageDataSource.lastReturnTime }}
                    </p>
                    <p v-else></p>
                </li>
              <li v-if="Number(pageDataSource.isMediumPackage) === 1">
                <p>中包装数量</p>
                <p>{{ pageDataSource.mediumPackage }}</p>
              </li>
            </ul>
          </div>
          <div class="product-num-box">
            <p class="label-num-name">
              {{
                Number(pageDataSource.isMediumPackage) === 1 ? "中包装" : "数量"
              }}
            </p>
            <!-- <p class="label-num-name">数量</p> -->
            <!-- <b-field>
              <b-numberinput
                size="is-small"
                v-model="detailsData.count"
                placeholder="1"
                min="1"
              ></b-numberinput>
              <p
                class="medium-packaging"
                v-if="Number(pageDataSource.isMediumPackage) === 1"
              >
                购买数量<span>
                  {{
                    Number(detailsData.count) * pageDataSource.mediumPackage
                  }}</span
                >
              </p>
            </b-field> -->
            <NumberInput
              :list="detailsData"
              @isDetailsData="isDetailsData"
              @handleSign="handleSign(detailsData)"
            >
            <template slot="top">
                <p
                  class="medium-packaging"
                  v-if="Number(pageDataSource.isMediumPackage) === 1"
                >
                  <span>中包装(1中包装={{pageDataSource.mediumPackage}}{{pageDataSource.sellUnit}})</span>
                </p>
              </template>
              <template slot="dome">
                <p
                  class="medium-packaging"
                  v-if="Number(pageDataSource.isMediumPackage) === 1"
                >
                  实际购买<span>
                    {{
                      Number(detailsData.totalNumber) *
                      pageDataSource.mediumPackage
                    }}</span>
                    <span>{{pageDataSource.sellUnit}}</span>
                </p>
              </template>
            </NumberInput>
            <p class="stock" v-if="isLogin && !isExternal && pageDataSource.isFactoryAndBelongProductFactory == 0">
              库存{{
                pageDataSource.availableNum > 99
                  ? "99+"
                  : pageDataSource.availableNum
              }}
            </p>
            <p class="stock" v-if="isLogin && !isExternal && pageDataSource.isFactoryAndBelongProductFactory == 1">
              库存{{pageDataSource.availableNum}}
            </p>
          </div>
          <div class="to-shop-box">
            <div class="button-box">
              <p
                v-if="
                  !disabledAddCart &&
                  detailsData.totalNumber != 0 &&
                  pageDataSource.isInvalid &&
                  isDetai != '0' &&
                  !isExternal
                "
                class="add-button"
                @click="addCart"
              >
                加入购物车
              </p>
              <p v-else class="add-button is-disabled">加入购物车</p>
            </div>
            <div
              class="stockout"
              v-if="
                pageDataSource.availableNum == 0 &&
                pageDataSource.preferenticePrice != null &&
                !isExternal
              "
              @click="handelStockout(pageDataSource)"
            >
              <p>缺货登记</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="product-info-box">
      <b-tabs v-model="activeTab">
        <b-tab-item label="商品参数">
          <div class="list-info-box">
            <div
              class="info-every"
              v-for="(item, index) in infoLabelList"
              :key="index"
            >
              <p>{{ item.label }}</p>
              <p v-if="item.props != 'productCode'">{{ pageDataSource[item.props] }}</p>
              <p v-if="item.props == 'productCode'"></p>
            </div>
          </div>
        </b-tab-item>
        <!-- <b-tab-item label="商品说明书">
          <div style="height:417px;">
            <h1>{{ pageDataSource.instruction }}</h1>
          </div>
        </b-tab-item>-->
        <b-tab-item label="商品详情">
          <div id="detail"></div>
          <div class="noInfoList" v-if="!productDetail">
            <div class="empty-list">
              <img src="../../user-center/order/images/pic_empty.png" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="商品说明书">
          <div id="detail" v-if="instructionList.content">
              <b-input type="textarea" v-model="instructionList.content" disabled></b-input>
          </div>
          <div class="noInfoList" v-else>
            <div class="empty-list">
              <img src="../../user-center/order/images/pic_empty.png" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
    </div>
    <PageFooter></PageFooter>
    <!-- <div class="success-bg" v-if="isSuccess">
      <div class="success">已加入购物车</div>
    </div> -->
    <div class="success-bg" v-if="isSuccess">
        <div class="success-detail" v-if="pageDataSource.superDiscountProductPrice">
            <div v-if="detailsData.totalNumber > pageDataSource.superDiscountProductNum">
                <p>前{{pageDataSource.superDiscountProductNum}}{{pageDataSource.sellUnit}}&nbsp;&nbsp;{{pageDataSource.superDiscountProductPrice}}元{{pageDataSource.sellUnit}}</p>
                <p>超过{{pageDataSource.superDiscountProductNum}}{{pageDataSource.sellUnit}}&nbsp;&nbsp;{{pageDataSource.preferenticePrice}}元{{pageDataSource.sellUnit}}</p>
            </div>
            <div v-else>
                <p>特价商品</p>
                <p>成功加入购物车</p>
            </div>
        </div>
      <div class="success-detail" v-else>{{ successText }}
        <p v-if="pageDataSource.isMediumPackage == 1 && !pageDataSource.notLowerSale && !pageDataSource.limitSaleNumber
        ">购买{{detailsData.totalNumber}}个中包装，实际购买{{Number(detailsData.totalNumber) *pageDataSource.mediumPackage}}
        {{pageDataSource.sellUnit}}</p>
      </div>
    </div>
    <div class="enshrine-bg" v-if="isEnshrine">
      <div class="md-enshrine">{{ isText }}</div>
    </div>
    <Dialog :isDialog="isDialog">
      <div class="dialog-stockout">已完成缺货登记 {{ time }}s</div>
    </Dialog>
  </div>
</template>
<script>
import { HeadeSearch } from "@components/head-search";
import { PageFooter } from "@components/page-footer";
import { PageTop } from "@components/page-top";
import { infoLabelList } from "./product-mock-data.js";
import { MainTabs } from "@components/main-tabs";
import { NumberInput } from "@components/number-input";
import { AccountStorage } from "@services/account";
import { Dialog } from "@components/dialog";
import { ActivityLine } from "@components/activity-line";
const accountStorage = new AccountStorage();
export default {
  components: {
    HeadeSearch,
    PageFooter,
    PageTop,
    MainTabs,
    NumberInput,
    Dialog,
    ActivityLine
  },
  data() {
    return {
      // invalidText: "",
      isHover: true,
      isLogin: false,
      tabActive: -1,
      isShowBigBox: false,
      isShowfloat: false,
      productId: -1,
      activeTab: 0,
      bigPicture: "", //图片展示的大图
      imgArr: "", //多个图片的数据
      imgClickNum: 0, //多个图片的左右切换计数
      pageDataSource: {},
      detailsData: {
        hotProduct: [],
        totalNumber: 1,
        limitSaleNumber: "",
        notLowerSale: ""
      },
      isDetai: "",
      percentObj: {
        percentX: 0,
        percentY: 0
      },
      // 商品详情的label
      infoLabelList,
      isOnHover: false,
      isShowTriangle: false,
      masterUrl: "",
      isSuccess: false, // 加入购物车成功后提示
      isEnshrine: false, // 收藏成功提示
      isText: "",
      successText: "",
      isDialog: false,
      time: 3,
      isExternal: false,
      productDetail: null,
      instructionList:{},
      isOtherAccount:true
    };
  },
  async created() {
    this.productId = this.$route.params.id;
    await this.productDetailsData();
    await this.leftImgActive();
    this.getDetails();
    this.queryInstructions();
    // 判断活动的内容长度，是否展示更多的小三角
    if (this.$refs.activeSpan) {
      let width = this.$refs.activeSpan.offsetWidth;
      if (width >= 443) {
        this.isShowTriangle = true;
      }
    }
  },
  mounted(){
    let userInfo = accountStorage.getCacheUserInfo();
    let userId = userInfo.account;
    if(userId.charAt(0)=='8') {
      this.isOtherAccount = true;
    }
    if(userInfo){
      this.isExternal = userInfo.type == 1 ? true : false
    }
  },
  computed: {
    invalidText() {
      let text = "";
      //无货 失效(下架 冻结 ) --- this.pageDataSource.ifFrozen ===0  正常   不等于0 失效
      if (JSON.stringify(this.pageDataSource) == "{}") {
        return (text = "");
      }
      if (
        (this.pageDataSource.ifFrozen && this.pageDataSource.ifFrozen !== 0) ||
        this.pageDataSource.status !== "0"
      ) {
        text = "商品已失效";
      } else if (this.pageDataSource.availableNum == "0") {
        return (text = "已售罄");
      }
      if (Number(this.pageDataSource.isMediumPackage) === 1) {
        if (
          Number(this.detailsData.totalNumber) *
            Number(this.pageDataSource.mediumPackage) >
          this.pageDataSource.availableNum
        ) {
          text = "库存不足";
        }
      } else {
        if (this.detailsData.totalNumber > this.pageDataSource.availableNum) {
          text = "库存不足";
        }
      }
      if (!this.pageDataSource.isInvalid) {
        text = "失效";
      }
      return text;
    },
    // 判断商品状态（是否禁用加入购物车按钮）
    // isVisibleAddCart() {
    //   let status = this.invalidText.length > 0 ||
    //     this.pageDataSource.availableNum === 0 ||
    //     this.detailsData.count > this.pageDataSource.availableNum &&
    //     this.isLogin;
    //   return status
    // },
    // 是否禁用加入购物车按钮
    disabledAddCart() {
      const a = this.isLogin;
      const b =
        (this.pageDataSource.ifFrozen && this.pageDataSource.ifFrozen !== 0) ||
        this.pageDataSource.status !== "0";
      let c = null;
      // 如果启用中包装，拿中包装数量*中包装管控 对比库存
      // 没有启用中包装，直接拿count 对比库存
      if (Number(this.pageDataSource.isMediumPackage) === 1) {
        c =
          Number(this.detailsData.totalNumber) *
            Number(this.pageDataSource.mediumPackage) >
          this.pageDataSource.availableNum;
      } else {
        c = this.detailsData.totalNumber > this.pageDataSource.availableNum;
      }
      return a && (b || c);
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.productId = to.params.id;
    this.productDetailsData();
    next();
  },
  methods: {
    isDetailsData(val) {
      this.isDetai = val;
    },
    // ++
    handleSign(item) {
      this.isDetai = item.totalNumber;
    },
    onHoverEvent(isHover) {
      this.isOnHover = isHover;
    },
    onClickTab(event) {
      // window.sessionStorage.setItem("cindex",0);
//  if (window.sessionStorage.getItem("cindex")) {
//       } else {
//         window.sessionStorage.setItem("cindex", 0);
//       }
        event.children.map(item=>{
          item.pagenum =0
          item.datalist =[]
        })
       if (this.isExternal) {
        this.$buefy.toast.open({
          message: "您尚未成为正式会员，无法进入该页面",
          type: "is-danger"
        });
        this.$router.push({
          path: "/"
        });
      } else {
        this.$router.push({
          // path: "/channel/channel-detail/" + event.index + "/" +Number(window.sessionStorage.getItem("cindex"))
          path: "/channel/channel-detail/" + event.index + "/0"


        });
      }
    },
    leftImgActive() {
      this.bigPicture = this.pageDataSource.mainImageUrl;
      this.imgArr = this.pageDataSource.imageUrlList.slice(
        this.imgClickNum,
        this.imgClickNum + 3
      );
      this.imgClickNum = 3;
    },
    onMouseMaskIn() {
      this.isShowBigBox = true;
      this.isShowfloat = true;
    },
    onMouseMaskOut() {
      this.isShowBigBox = false;
      this.isShowfloat = false;
    },
    onMouseMaskMove(_event) {
      var left = _event.clientX - this.$refs.smallBox.offsetLeft - 75;
      var top = _event.clientY - this.$refs.smallBox.offsetTop - 75;
      if (left < 0) {
        left = 0;
      } else if (
        left >
        _event.toElement.offsetWidth - this.$refs.objFloatBox.offsetWidth
      ) {
        left =
          _event.toElement.offsetWidth - this.$refs.objFloatBox.offsetWidth;
      }

      if (top < 0) {
        top = 0;
      } else if (
        top >
        _event.toElement.offsetHeight - this.$refs.objFloatBox.offsetHeight
      ) {
        top =
          _event.toElement.offsetHeight - this.$refs.objFloatBox.offsetHeight;
      }
      this.$refs.objFloatBox.style.left = left + "px";
      this.$refs.objFloatBox.style.top = top + "px";
      this.percentObj.percentX =
        left /
        (_event.toElement.offsetWidth - this.$refs.objFloatBox.offsetWidth);
      this.percentObj.percentY =
        top /
        (_event.toElement.offsetHeight - this.$refs.objFloatBox.offsetHeight);
      this.$refs.objBigBoxImage.style.left =
        -this.percentObj.percentX *
          (this.$refs.objBigBoxImage.offsetWidth -
            this.$refs.objBigBox.offsetWidth) +
        "px";
      this.$refs.objBigBoxImage.style.top =
        -this.percentObj.percentY *
          (this.$refs.objBigBoxImage.offsetHeight -
            this.$refs.objBigBox.offsetHeight) +
        "px";
    },
    //获取用户ID
    getUserId() {
      let userInfo = accountStorage.getCacheUserInfo();
      if (userInfo) {
        return userInfo.userId;
      }
      return;
    },
    async addCart() {
      let flag =
        this.pageDataSource.ifFrozen &&
        this.pageDataSource.ifFrozen === 0 &&
        this.pageDataSource.status === "0";
      if (!this.isLogin) {
        this.$router.push({ path: "/account/login" });
      } else if (!flag) {
        if (
          (this.pageDataSource.ifFrozen &&
            this.pageDataSource.ifFrozen !== 0) ||
          this.pageDataSource.status !== "0"
        ) {
          // 无效商品
          return;
        } else if (Number(this.pageDataSource.availableNum) === 0) {
          return;
          // 无货商品
        }
        if (
          this.pageDataSource.notLowerSale != 0 &&
          this.pageDataSource.limitSaleNumber != 0
        ) {
          if (this.detailsData.totalNumber < this.pageDataSource.notLowerSale) {
            this.successText = "您输入的购买数量小于最低数量要求！";
            this.isSuccess = true;
            setTimeout(() => {
              this.isSuccess = false;
            }, 2000);
            return;
          } else if (
            this.detailsData.totalNumber > this.pageDataSource.limitSaleNumber
          ) {
            this.successText = "您输入的购买数量大于最多数量限制！";
            this.isSuccess = true;
            setTimeout(() => {
              this.isSuccess = false;
            }, 2000);
            return;
          } else {
            this.successText = "已加入购物车";
          }
        } else {
          this.successText = "已加入购物车";
        }

        const URL = "/pjyy-deepexi-order-center/api/v1/cart/addGoodsToCartList";
        const data = {
          //  2021-10-27 huangyi 修改productId不转为number类型
          // productId: Number(this.productId),
          productId: this.productId,
          userId: Number(this.getUserId()),
          totalNumber: this.detailsData.totalNumber
        };
        const result = await this.$getHttpClient().post(URL, data);
        console.log(result)
        if (result.data.code === "200") {
          this.isSuccess = true;
          setTimeout(() => {
            this.isSuccess = false;
          }, 2000);
          this.$refs.handleGetSum.getSum();
        }
      }
    },
    //处方类型
    prescriptionCategory(val) {
      let obj = {};
      switch (val) {
        case "8906":
          obj.text = "OTC";
          obj.bgColor = "green";
          obj.type = "乙";
          break;
        case "8905":
          obj.text = "OTC";
          obj.bgColor = "red";
          obj.type = "甲";
          break;
        case "8904":
          obj.text = "RX";
          obj.bgColor = "red";
          break;
        default:
          break;
      }
      return obj;
    },
    //左侧图片列表
    getProductList(imgList) {
      let imgArr = [];
      if (imgList && imgList.length > 0) {
        imgList.forEach(elemnt => {
          imgArr.push(elemnt.imageUrl);
        });
      }
      return imgArr;
    },
    //有效期
    setFfectivePeriod(obj) {
      if (obj && obj.length > 10) {
        obj = obj.slice(0, 10);
      }
      return obj;
    },
    async productDetailsData() {
      const URL = `/pjyy-deepexi-product-center/api/v1/product/PcProduct/getById?id=${this.productId}`;
      let result = await this.$getHttpClient().get(URL);
      if (result.data.code === "100001") {
        this.isLogin = true;
      }
      let dataSource = result.data.data;
      this.detailsData.limitSaleNumber = dataSource.limitSaleNumber;
      this.detailsData.notLowerSale = dataSource.notLowerSale;
      this.pageDataSource = result.data.data;
      this.pageDataSource = {
        ...dataSource,
        ption: dataSource.prescription,
        control: dataSource.isMediumPackage
      };
      if (this.pageDataSource.category) {
        if (this.pageDataSource.category=='1') {
         this.pageDataSource.health = '甲' ;

        } else if (this.pageDataSource.category=='2') {
         this.pageDataSource.health = '乙' ;

        }else if (this.pageDataSource.category=='3') {
         this.pageDataSource.health = '丙' ;

        }
      } else {
         this.pageDataSource.health = '' ;
      }

      this.pageDataSource.availableNum = Number(
        this.pageDataSource.availableNum
      );
      if (this.pageDataSource.control === 1) {
        this.pageDataSource.control = "是";
      } else {
        this.pageDataSource.control = "否";
      }
      if (this.pageDataSource.ption === "8904") {
        this.pageDataSource.ption = "RX";
      } else if (this.pageDataSource.ption === "8905") {
        this.pageDataSource.ption = "甲 OTC";
      } else if (this.pageDataSource.ption === "8906") {
        this.pageDataSource.ption = "乙 OTC";
      } else {
        this.pageDataSource.ption = "";
      }
      if (this.pageDataSource) {
        this.pageDataSource.nearEffectivePeriod = this.setFfectivePeriod(
          this.pageDataSource.nearEffectivePeriod
        );
        this.pageDataSource.farEffectivePeriod = this.setFfectivePeriod(
          this.pageDataSource.farEffectivePeriod
        );
        // if (
        //   (this.pageDataSource.ifFrozen &&
        //     this.pageDataSource.ifFrozen !== 0) ||
        //   this.pageDataSource.status !== "0"
        // ) {
        //   this.invalidText = "商品已失效";
        // } else if (Number(this.pageDataSource.availableNum) === 0) {
        //   this.invalidText = "商品无货";
        // }
        if (dataSource.prescription) {
          this.pageDataSource.prescription = this.prescriptionCategory(
            dataSource.prescription
          );
        }
        this.pageDataSource.imageUrlList = this.getProductList(
          dataSource.vosList
        );
        // this.leftImgActive();
        if (this.pageDataSource.unitPrice === null) {
          this.isLogin = false;
          this.pageDataSource.unitPrice = "价格登录后可见";
          if (dataSource.type === null || dataSource.type === 1) {
            // type=0 表示全部用户的活动   type=1表示该商品没有活动，type=null 表示个别用户展示活动
            this.pageDataSource.active = "";
          } else {
            this.pageDataSource.active = "价格登录后可见";
          }
        } else {
          this.isLogin = true;
          this.pageDataSource.unitPrice = dataSource.unitPrice;
          let num1 = dataSource.numberOne
            ? "满" +
              dataSource.numberOne +
              dataSource.sellUnit +
              "减" +
              (dataSource.moneyOne || 0).toFixed(2) +
              "元"
            : "";
          // let num2 = dataSource.numberTwo ? ("；满" + dataSource.numberTwo + dataSource.sellUnit + '减' + (dataSource.moneyTwo || 0).toFixed(2) + '元') : ''
          // let num3 = dataSource.numberThree ? ("；满" + dataSource.numberThree + dataSource.sellUnit + '减' + (dataSource.moneyThree || 0).toFixed(2) + '元') : ''
          this.pageDataSource.active = num1;
          dataSource.availableNum = Number(dataSource.availableNum);
          // if (dataSource.availableNum > 99) {
          //   this.pageDataSource.availableNum = "99+";
          // } else {
          //   this.pageDataSource.availableNum = dataSource.availableNum;
          // }
          this.pageDataSource.availableNum = dataSource.availableNum;
        }
      }
    },
    // 点击放大缩略图
    previewPictures(url) {
      this.bigPicture = url;
    },
    toSwichPicture(type) {
      if (type === "left") {
        if (this.imgClickNum > 3) {
          this.imgClickNum = this.imgClickNum - 3;
          this.imgArr = this.pageDataSource.imageUrlList.slice(
            this.imgClickNum - 3,
            this.imgClickNum
          );
        }
      } else {
        if (this.imgClickNum < this.pageDataSource.imageUrlList.length) {
          this.imgArr = this.pageDataSource.imageUrlList.slice(
            this.imgClickNum,
            this.imgClickNum + 3
          );
          this.imgClickNum = this.imgClickNum + 3;
        }
      }
    },
    // 点击收藏
    async hanndleEnshrine() {
      if (this.pageDataSource.isCollect) {
        let url = `/pjyy-deepexi-product-center/api/v1/product/productCollection/remove?id=${this.pageDataSource.id}`;
        this.$getHttpClient()
          .delete(url)
          .then(res => {
            if (res.data.code === "200") {
              this.pageDataSource.isCollect = !this.pageDataSource.isCollect;
              this.isText = "已取消收藏商品";
              this.isEnshrine = true;
              setTimeout(() => {
                this.isEnshrine = false;
              }, 1000);
            }
          });
      } else {
        let id = this.$route.params.id;
        let data = {
          productId: id
        };
        let url = `/pjyy-deepexi-product-center/api/v1/product/productCollection/addCollect`;
        let result = await this.$getHttpClient().post(url, data);
        if (result.data.code === "200") {
          this.isText = "已收藏商品";
          this.isEnshrine = true;
          this.pageDataSource.isCollect = !this.pageDataSource.isCollect;
          setTimeout(() => {
            this.isEnshrine = false;
          }, 1000);
        }
      }
    },
    async handelStockout(item) {
      let that = this;
      let data = {
        productId: item.id
      };
      let url = `/pjyy-deepexi-product-center/api/v1/product/pcRegister/addRegister`;
      this.$getHttpClient()
        .post(url, data)
        .then(res => {
          if (res.data.code === "200") {
            this.isDialog = true;
            let timer = setInterval(() => {
              that.time--;
              if (that.time == 0) {
                clearInterval(timer);
              }
            }, 1000);
            setTimeout(() => {
              this.isDialog = false;
            }, 3000);
            this.time = 3;
          }
        });
    },
    getDetails(){
      let url = `/pjyy-deepexi-product-center/api/v1/product/PcProductContent/pc/getDetailByProductId?productId=${this.productId}`
      this.$getHttpClient().get(url).then(res=>{
      if(res.data.code === '200' && res.data.data){
          this.productDetail = res.data.data
          document.all.detail.innerHTML = this.productDetail.pcContent
        }
      })
    },
    queryInstructions(){
      let url = `/pjyy-deepexi-product-center/api/v1/product/instruction/getProductManualDetail?productId=${this.productId}`
      this.$getHttpClient().get(url).then(res=>{
        if(res.data.code === '200' && res.data.data){
          this.instructionList = res.data.data
        }
      })
    }
  }
};
</script>
<style src="./details.less" lang="less" scope>
</style>
