export default {
  props: {
    isDialog: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      islog: false
    };
  },
  watch: {
    isDialog: {
      handler: function handler() {
        this.islog = this.isDialog;
      },
      deep: true
    }
  },
  methods: {}
};